/* Contenedor principal del dashboard */
.dashboard-container {
    display: flex;
    height: 100vh; /* Ocupa toda la altura de la ventana */
  }
  
  /* Estilo de la barra lateral */
  .sidebar {
    width: 250px; /* Ancho de la barra lateral */
    background-color: #333; /* Fondo negro */
    color: white; /* Texto blanco */
    padding: 20px; /* Espaciado interno */
    display: flex;
    flex-direction: column; /* Alinea los elementos verticalmente */
    position: fixed; /* Fija la barra lateral a la izquierda */
    height: 100%; /* Asegura que la barra lateral ocupe toda la altura */
  }
  
  /* Contenido principal */
  .content {
    flex-grow: 1; /* Hace que el contenido ocupe todo el espacio restante */
    margin-left: 250px; /* Deja espacio para la barra lateral */
    padding: 20px; /* Espaciado interno */
    background-color: #f4f4f4; /* Fondo claro para el contenido */
  }
  